// import 'swiper/swiper.min.css';
import React, { useEffect, useState } from "react";
import "../src/assets/icons/font-awesome.css";
import "./App.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AOS from "aos";

import {
  runTransaction,
  onChildChanged as FirebaseChildChanged,
  onChildAdded as FirebaseChildAdded,
  getDatabase,
  get as FirebaseGet,
  remove as FirebaseRemove,
  onValue as FirebaseValue,
  ref as FirebaseRef,
  set as FirebaseSet,
  child as FirebaseChild,
  push as FirebasePush,
  update as FirebaseUpdate,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import { initializeApp } from "firebase/app";
import {
  initializeAuth,
  signInWithEmailAndPassword,
  signInAnonymously,
  createUserWithEmailAndPassword,
  signOut,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { once } from "firebase/database";

import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";

import { Route, Routes } from "react-router-dom";

import routes from "./pages/index";

function getLibrary(provider) {
  return new Web3(provider);
}

const firebaseConfig = {
  apiKey: "AIzaSyA-gYo8gCAACzJIu0tPqZ16tdLPK4fmMBs",
  authDomain: "mest-3b41d.firebaseapp.com",
  databaseURL: "https://workshop-894a4.europe-west1.firebasedatabase.app/",
  projectId: "mest-3b41d",
  storageBucket: "mest-3b41d.appspot.com",
  messagingSenderId: "801879872668",
  appId: "1:801879872668:web:060a88a471c929988c8959",
  measurementId: "G-W665L1JQYZ",
};

const firebase = initializeApp(firebaseConfig);
// const auth = initializeAuth(firebase, {persistence:browserLocalPersistence});
const auth = initializeAuth(firebase);
const db = getDatabase(firebase);

function App() {
  const [isLogin, SetLogin] = useState(false);
  const [email, SetEmail] = React.useState(false);
  const [currentEmail, SetCurrentEmail] = React.useState("");
  const [isAdmin, SetAdmin] = React.useState(false);
  const [addContent, SetAddContent] = React.useState(false);
  const [content, AddContent] = React.useState([]);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isClaimedTeam, setIsClaimedTeam] = useState(false);
  let listener;

  useEffect(() => {
    if (typeof listener !== "function") {
      listener = FirebaseValue(FirebaseRef(db, "contents"), (snapshot) => {
        if (snapshot.exists()) AddContent(Object.entries(snapshot.val()));
      });
      console.log(listener);
    }

    AOS.init({
      duration: 2000,
    });
    return () => {
      if (typeof listener === "function") {
        listener();
        listener = null;
      }
    };
  }, []);

  useEffect(() => {
    FirebaseValue(FirebaseRef(db, "claimdata"), (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        for (const key in data) {
          if (data[key].email === currentEmail) {
            setIsClaimed(data[key].isclaim);
          }
        }
      }
    });
  }, [currentEmail]);

  useEffect(() => {
    FirebaseValue(FirebaseRef(db, "claimteam"), (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        for (const key in data) {
          if (data[key].email === currentEmail) {
            setIsClaimedTeam(data[key].isclaim);
          }
        }
      }
    });
  }, [currentEmail]);

  console.log("deploying new....");

  return (
    <div>
      <div
        className="addContent"
        style={{ display: addContent ? "flex" : "none" }}
      >
        <div
          onClick={() => {
            SetAddContent(false);
          }}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          {" "}
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const collections = new FormData(e.target);
            const ref = FirebaseRef(db, "contents");
            const data = {
              title: collections.get("title"),
              url: collections.get("link"),
            };
            const key = FirebasePush(ref, data).key;
            FirebaseSet(FirebaseRef(db, "contents/" + key), data);
          }}
          className="contentToAdd"
        >
          <h4 style={{ marginBottom: 20 }}>Add Content</h4>
          <div className="form-group">
            <select
              className="form-control mr-0"
              id="exampleFormControlSelect1"
              style={{ padding: "22px 29px" }}
            >
              <option>Embed URL</option>
              <option>Polls</option>
            </select>
          </div>
          <div className="form-group" style={{ margin: "20px 0" }}>
            <input
              type="text"
              className="form-control"
              name="title"
              placeholder="Title"
              required
              style={{ marginRight: 5 }}
            />
            <input
              type="text"
              className="form-control"
              name="link"
              placeholder="URL"
              required
              style={{ marginLeft: 5 }}
            />
          </div>
          <button type="submit" className="btn-action style-2">
            <span>Add</span>
          </button>
          <ul style={{ padding: "20px 0" }}>
            {content.map((v, i) => (
              <li
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 5,
                }}
              >
                <a>
                  {" "}
                  <span
                    style={{
                      width: 500,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      display: "block",
                      overflow: "hidden",
                    }}
                  >
                    {v[1].title}
                  </span>
                </a>
                <span
                  onClick={() => {
                    FirebaseRemove(FirebaseRef(db, "contents/" + v[0]));
                    AddContent((prev) =>
                      prev.filter((value) => value[0] != v[0])
                    );
                  }}
                  style={{
                    background: "#FF5C5C",
                    color: "white",
                    padding: 7,
                    cursor: "pointer",
                    marginRight: 10,
                    borderRadius: 8,
                  }}
                >
                  Delete
                </span>
              </li>
            ))}
          </ul>
        </form>
      </div>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Routes>
          {routes.map((data, index) => (
            <Route
              exact={true}
              path={data.path}
              element={
                <data.component
                  isLogin={isLogin}
                  isAdmin={isAdmin}
                  SetLogin={SetLogin}
                  content={content}
                  email={email}
                  SetEmail={SetEmail}
                  SetAdmin={SetAdmin}
                  currentEmail={currentEmail}
                  SetCurrentEmail={SetCurrentEmail}
                  SetAddContent={SetAddContent}
                  sendFeedback={(data) => {
                    const ref = FirebaseRef(db, "feedback");
                    const key = FirebasePush(ref, data).key;
                    FirebaseSet(FirebaseRef(db, "feedback/" + key), data);
                  }}
                  sendClaim={(data) => {
                    const ref = FirebaseRef(db, "claimdata");
                    const key = FirebasePush(ref, data).key;
                    FirebaseSet(FirebaseRef(db, "claimdata/" + key), data);
                  }}
                  sendClaimTeam={(data) => {
                    const ref = FirebaseRef(db, "claimteam");
                    const key = FirebasePush(ref, data).key;
                    FirebaseSet(FirebaseRef(db, "claimteam/" + key), data);
                  }}
                  isClaimed={isClaimed}
                  isClaimedTeam={isClaimedTeam}
                />
              }
              key={index}
            />
          ))}
        </Routes>
      </Web3ReactProvider>
    </div>
  );
}

export default App;
