const menus = [
    {
        id: 0,
        name: 'About',
        links: '#about'
    },{
        id: 1,
        name: 'Content',
        links: '#content'
    },
    // {
    //     id: 2,
    //     name: 'Statistics',
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Team',
    //             links: '/team'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Road Map',
    //             links: '/road-map'
    //         },
    //         {
    //             id: 3,
    //             sub: 'Our Mission',
    //             links: '/our-mission'
    //         },
    //         {
    //             id: 4,
    //             sub: 'Blog List',
    //             links: '/blog-list'
    //         },
    //         {
    //             id: 5,
    //             sub: 'Blog Grid',
    //             links: '/blog-grid'
    //         },
    //         {
    //             id: 6,
    //             sub: 'Blog Single',
    //             links: '/blog-single'
    //         },
    //         {
    //             id: 7,
    //             sub: 'FAQ',
    //             links: '/faq'
    //         },
    //     ],
    // },
    {
        id: 2,
        name: 'EPG Report',
        links: '#report'
    },
    {
        id: 3,
        name: 'Team',
        links: '#participants'
    },
    {
        id: 4,
        name: 'The Office',
        links: '#theoffice'
    },
    {
        id: 4,
        name: 'Contact',
        links: '#contact'
    },
    
]

export default menus;