import React from "react";
import dataBlog from "../assets/fake-data/data-blog";
import dataFaq from "../assets/fake-data/data-faq";
import dataMontono from "../assets/fake-data/data-montono";
import dataRoadMap from "../assets/fake-data/data-roadmap2";
import dataTeam from "../assets/fake-data/data-team";
import dataTechnology from "../assets/fake-data/data-technology";
import dataTestimonials from "../assets/fake-data/data-testimonials2";
import FooterStyle2 from "../components/footer/FooterStyle2";
import Header from "../components/header/Header";
import AboutTwo from "../components/layouts/AboutTwo";
import About from "../components/layouts/About";
import Blog from "../components/layouts/Blog";
import Statistics from "../components/layouts/Statistics";
import CounterTwo from "../components/layouts/CounterTwo";
import FAQ3 from "../components/layouts/FAQ3";
import Infomation from "../components/layouts/Infomation";
import Montono2 from "../components/layouts/Montono2";
import Newsletter from "../components/layouts/Newsletter";
import RoadMap2 from "../components/layouts/RoadMap2";
import Team from "../components/layouts/Team";
import Technology from "../components/layouts/Technology";
import Testimonials2 from "../components/layouts/Testimonials2";
import SliderThree from "../components/slider/SliderThree";
import Contact from "./Contact";
import Contact2 from "../components/layouts/Contact2";
import { useNavigate } from "react-router-dom";
import Content from "../components/layouts/Content";

import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";

import TheOffice from "../components/layouts/TheOffice";

import { Close } from "@mui/icons-material";

const injected = new InjectedConnector({
  supportedChainIds: [137],
});

const HomeOnePage = (props) => {
  const { active, account, library, connector, activate, deactivate } =
    useWeb3React();
  const [metamask, SetMetamask] = React.useState(false);

  async function connect() {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          return result;
        });
    } else {
      // alert(errorMessage);
    }
    if (
      window.ethereum.networkVersion !== "137" ||
      window.ethereum.networkVersion !== 137
    ) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(137) }],
        });
      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: "Polygon Mainnet",
                chainId: Web3.utils.toHex(137),
                nativeCurrency: {
                  name: "MATIC",
                  decimals: 18,
                  symbol: "MATIC",
                },
                rpcUrls: [
                  "https://polygon-mainnet.g.alchemy.com/v2/NZ9FtMQHxKU_RIN2avZbqhMyA21TomfI",
                ],
              },
            ],
          });
        }
      }
    }

    try {
      await activate(injected);
    } catch (ex) {
      console.log(ex);
    }
  }

  async function disconnect() {
    try {
      deactivate();
    } catch (ex) {
      console.log(ex);
    }
  }

  const navigate = useNavigate();
  console.log(typeof props.content);
  console.log(props.content);
  return (
    <div className="home-3 one-page">
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          display: metamask ? "block" : "none",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1001,
          background: "#000000CF",
          backdropFilter: "blur(10px)",
        }}
      >
        <div
          onClick={() => {
            SetMetamask(false);
          }}
          style={{
            width: "100%",
            height: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1002,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 20,
              right: 20,
              cursor: "pointer!important",
            }}
          >
            <Close fontSize="large" />
          </div>
        </div>

        <div className="video-container">
          <iframe
            src={
              metamask
                ? "https://player.vimeo.com/video/801582108?h=685ed976a5"
                : ""
            }
            frameborder="0"
            allow="accelerometer; autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <Header />
      {/* <SliderThree /> */}
      <AboutTwo
        active={active}
        account={account}
        library={library}
        connector={connector}
        activate={activate}
        deactivate={deactivate}
        connect={connect}
        disconnect={disconnect}
        currentEmail={props.currentEmail}
        sendClaim={props.sendClaim}
        isClaimed={props.isClaimed}
        metamask={metamask}
        SetMetamask={SetMetamask}
      />
      <Content
        SetAddContent={props.SetAddContent}
        isAdmin={props.isAdmin}
        data={props.content}
      />
      <Statistics />
      <Team
        active={active}
        account={account}
        library={library}
        connector={connector}
        activate={activate}
        deactivate={deactivate}
        connect={connect}
        disconnect={disconnect}
        metamask={metamask}
        SetMetamask={SetMetamask}
        currentEmail={props.currentEmail}
        data={[
          "Abdulla Alashram",
          "Rashid Al Falasi",
          "Samer Awajan",
          "Abdulla Alhashimi",
          "Ahmed Hegazy",
          "Hamad Al Shamsi",
          "Maya AlBaradie",
          "Mohammed Ali",
          "Obaid Mohammad Al Qatami",
          "Shady Fayek",
          "Jasem AlAwadhi",
          "Angelo Vassiliades",
          "Wissam Aboueida",
          "Mohammed Bahaa",
          "Ahmed Al Badi",
          "Igor Arkhypenko",
          "Dr. Marwan Alzarouni",
          "Vijay Venkataraman",
          "Rolando Zubiran",
        ]}
        sendClaimTeam={props.sendClaimTeam}
        isClaimedTeam={props.isClaimedTeam}
      />
      <TheOffice />
      <Contact sendFeedback={props.sendFeedback} />
    </div>
  );
};

export default HomeOnePage;
