import React from 'react';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Scrollbar, A11y } from 'swiper';

const Content = (props) => {
    const [header, SetHeader] = React.useState("MESMR");
    const defaultData = [{url:"https://player.vimeo.com/video/793649803?h=b7d3ebc5f0", title:'WillO'}, {url:"https://player.vimeo.com/video/793290155?h=894b6e295d", title:'Emirates Strategy'},{url: "https://player.vimeo.com/video/792771124?h=5317072e8f", title:'RRD'}];
    const data = props.data;

    return (
        <section style={{paddingTop:100}} id="content">
             <div className="block-text center" style={{padding:0, margin:0}}>
                                <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>Content</span></h1>
                                <div style={{position:'relative', justifyContent:'center', display:'flex'}}>
                                <h3 data-aos="fade-up" data-aos-duration="1000" style={{padding:0, margin:0}}>{header ? header : 'Content'} 
                                
                                

                                </h3>
                                {props.isAdmin &&
                                    <button 
                                    onClick={()=>{
                                        props.SetAddContent(true);
                                    }}
                                    style={{
                                        width: 50,
                                        height: 50,
                                        background: '#00000064',
                                        color: 'white',
                                        fontSize:'20pt',
                                        borderRadius: '50%',
                                        border: '5px solid purple',
                                        position:'relative',
                                        right: -20
                                    }}
                                    data-aos="fade-up" data-aos-duration="1000"
                                    >
                                        +
                                    </button>
                                }
                                </div>
                                <div style={{width: 50, height: 8, background:'#fd562a', margin:'auto', position:'relative'}}>

                                </div>
                                
                                
                                {/* <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">Write Us Message for<br /> Any Reason</h3> */}
                            </div>
                <div className="row">
                    <div className="col-md-12" data-aos="fade-up" data-aos-duration="1000">
                    <Swiper
                        onActiveIndexChange={e=>{
                            const collections = [...defaultData, ...data.map((value) => value[1])];
                            SetHeader(collections[e.activeIndex].title);
                        }}
                        modules={[Pagination , Scrollbar, A11y]}
                        spaceBetween={30}
                        pagination={{ clickable: true}}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                              },
                            767: {
                              slidesPerView:1,
                            },
                            991: {
                              slidesPerView: 1,
                            },
                          }}
                        scrollbar={{ draggable: true }}                
                    >
                         {
                            [...defaultData, ...data.map((value) => value[1])].map((data,index) => (
                                <SwiperSlide key={"default"+index}>
                                    <div style={{width: '100vw', height:'45vw', position:'relative', paddingTop: 50}}>
                                        <div style={{width: '70%', height:'90%', position:'relative', margin:'auto'}}>
                                        <iframe src={/(\.ppt|\.doc)/.test(data.url) ? 'https://view.officeapps.live.com/op/embed.aspx?src=' +  data.url : data.url} style={{width:'100%', height:'100%'}} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                       
                    </div>
                </div>
        </section> 
    );
}

export default Content;