import React , { useState } from 'react';
import { Link } from 'react-router-dom'
import item from '../../assets/fake-data/item';

import Web3 from 'web3';
import { useEffect } from 'react';
import axios from 'axios';


const abi = [
    {
      "inputs": [],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "approved",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "Approval",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "bool",
          "name": "approved",
          "type": "bool"
        }
      ],
      "name": "ApprovalForAll",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "Transfer",
      "type": "event"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "_claimed",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_tokenId",
          "type": "uint256"
        }
      ],
      "name": "burn",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        }
      ],
      "name": "claim",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "checkMe",
          "type": "address"
        }
      ],
      "name": "claimed",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "getApproved",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        }
      ],
      "name": "isApprovedForAll",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "name",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "ownerOf",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "safeTransferFrom",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        },
        {
          "internalType": "bytes",
          "name": "data",
          "type": "bytes"
        }
      ],
      "name": "safeTransferFrom",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "internalType": "bool",
          "name": "approved",
          "type": "bool"
        }
      ],
      "name": "setApprovalForAll",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes4",
          "name": "interfaceId",
          "type": "bytes4"
        }
      ],
      "name": "supportsInterface",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "symbol",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_tokenId",
          "type": "uint256"
        }
      ],
      "name": "tokenURI",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "transferFrom",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ];

const AboutTwo = (props) => {

    const active=props.active;
    const account=props.account;
    const library=props.library;
    const connector=props.connector;
    const activate=props.activate;
    const deactivate=props.deactivate;
    const connect=props.connect;
    const disconnect=props.disconnect;
    const web3 = new Web3(window.ethereum);
    const SetMetamask = props.SetMetamask;
    
    const [contract, SetContract] = useState(null);
    const [claimed, SetClaimed] = useState(false);
    const [claiming, SetClaiming] = useState(false);

    useEffect(()=>{
        SetContract(new web3.eth.Contract(abi, "0xd879BB74aF053e4f727627d2DEB520061ce27717"));
        //0xd879BB74aF053e4f727627d2DEB520061ce27717
    }, [])

    useEffect(()=>{
        const check = async () => {
            // SetClaimed(await contract.methods.claimed().call());
            try {
                if(contract && typeof account !== "undefined") SetClaimed(await contract?.methods.claimed(account).call());
            } catch (err) {
                console.log(err);
            }
        } 
        check();
    },[active]);

    const [data] = useState(
        {
            subtitle: 'About Us',
            title: 'Hight Quality NFT  Collections',
            desc1: 'Sed ut perspiciatis unde omnis iste natus enim ad minim veniam, quis nostrud exercit',
            desc2: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occae cat cupidatat non proident, sunt in culpa qui officia dese runt mollit anim id est laborum velit esse cillum dolore eu fugiat nulla pariatu epteur sint occaecat',
        }
    )

  return (
    <section className="tf-section section-about" id='about'>
        <div className="container">
            <div className="row reverse">
                <div className="col-xl-7 mobilehide">
                    <div className="group-image">
                        <div className="left">
                            <div className="item bg-1 big"><div>
                            <video src={'/nfts/EPG NFT 20 Feb_1.mp4'} style={{width:500,position:'relative', top: -25}}  autoPlay={true} loop={true} muted={true}  />
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-md-12">
                    <div className="block-text pt-12">
                         <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>About</span></h1>
                        {/* <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">{data.subtitle}</h5> */}
                        <h3 className="sub-title mb-58" data-aos="fade-up" data-aos-duration="1000">About</h3>
                        <p className="fs-21 mb-33" data-aos="fade-up" data-aos-duration="1000">An exclusive portal to access all content related to Web3 and Metaverse,
following the strategic workshop. Access this space to claim your NFTs, login
periodically to view new content and also contact the team.</p>
                    <button onClick={()=>{
                      SetMetamask(true);
                    }} className="btn-action" data-aos="fade-up" data-aos-duration="1200" style={{width:435, textAlign:'center', marginBottom: '1rem'}}>How to get a wallet</button>
                        {props.isClaimed === true ? (
                          <>
                          <a id="download1" 
                          download={"Workshop Overview NFT.mp4"} 
                          href={'/nfts/EPG NFT 20 Feb_1.mp4'} 
                          // onClick={e => document.getElementById("download1").click()}
                          className="btn-action style-2" >DOWNLOAD WORKSHOP OVERVIEW NFT</a>
                          <p style={{color:'white'}}>Thank you for claiming, please allow up to 12hrs to receive your NFT in your wallet.</p>
                          </>
                        ): (
                          <>
                          <a onClick={async (e)=>{
                              if(!props.isClaimed){
                                  e.preventDefault();
                              }
  
                              if(!active){ 
                                  await connect();   
                                }else{
                                  if(!claiming && !props.isClaimed && 
                                    // !claimed && 
                                    contract !== null){ 
                                    // if(!await contract?.methods.claimed(account).call()){
                                      try {
                                          SetClaiming(true);
                                          // const r = await contract.methods.claim(account).send({
                                          //   from: account
                                          // });
                                          // if(r.status) {
                                              SetClaimed(true);
                                              props.sendClaim({
                                                isclaim: true,
                                                email: props.currentEmail
                                              })
                                              axios({
                                                method: "post",
                                                url: "https://us-central1-mest-3b41d.cloudfunctions.net/contact",
                                                data: {
                                                  sendTo: "fuse@molotovcocktail.tv",
                                                  name: props.currentEmail,
                                                  message: `Your message is ${props.currentEmail} this email address cliam ${true} with ${account} this wallet address from claim overview workshop NFT.`,
                                                },
                                              });
                                              document.getElementById("download1").click();
                                          // }
                                          SetClaiming(false);
                                        } catch (err) {
                                          SetClaiming(false);
                                        }
                                    }
                                  }
                                // }
  
                          }}  
                          // download={"Workshop Overview NFT.mp4"} 
                          // href={'/nfts/EPG NFT 20 Feb_1.mp4'} 
                          className="btn-action style-2"  
                          data-aos="fade-up" data-aos-duration="1200" 
                          style={{width:450, textAlign:'center'}}>
                            {props.isClaimed ? "DOWNLOAD WORKSHOP OVERVIEW NFT" : 
                            (claiming ? "CLAIMING..." : 
                            (active) ? "CLAIM WORKSHOP OVERVIEW NFT" : "CLAIM WORKSHOP OVERVIEW NFT" ) }
                            </a>
                            <p style={{color:'white', textAlign: 'center'}}>Make sure you are on Polygon Mainnet</p>
                          </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

export default AboutTwo;