import Home01 from "./Home01";
import Home02 from "./Home02";
import Home03 from "./Home03";
import HomeOnePage from "./HomeOnePage";
import AboutUs from "./AboutUs";
import Collections from "./Collections";
import Team from "./Team";
import RoadMap from "./RoadMap";
import OurMission from "./OurMission";
import BlogList from "./BlogList";
import BlogGrid from "./BlogGrid";
import BlogSingle from "./BlogSingle";
import FAQ from "./FAQ";
import Contact from "./Contact";
import Login from "./Login";
import { Navigate } from "react-router-dom";

const routes = [
  {
    path: "/login",
    component: (props) => {
      return <Login {...props} />;
    },
  },
  {
    path: "/",
    component: (props) =>
      props.isLogin ? <HomeOnePage {...props} /> : <Navigate to="/login" />,
  },
  // { path: '/content', component: <Home02 />},
  // { path: '/statistics', component: <Home03 />},
  // { path: '/home-one-page', component: <HomeOnePage />},
  // { path: '/about', component: <AboutUs />},
  // { path: '/collections', component: <Collections />},
  // { path: '/participants', component: <Team />},
  // { path: '/road-map', component: <RoadMap />},
  // { path: '/our-mission', component: <OurMission />},
  // { path: '/blog-list', component: <BlogList />},
  // { path: '/blog-grid', component: <BlogGrid />},
  // { path: '/blog-single', component: <BlogSingle />},
  // { path: '/faq', component: <FAQ />},
  // { path: '/contact', component: props => { return <Contact {...props} />}},
];

export default routes;
