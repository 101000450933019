import React from 'react';
import CountUp from 'react-countup';

import icon from '../../assets/fake-data/icon';

const Statistics = () => {
    return (
        <section className="tf-section section-counter" id='report'>
            <div className="container">
            <div className="block-text center">
                <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>EPGReport</span></h1>
                <h1 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">EPG Workshop Report</h1>
            </div>
            <div data-aos="fade-up" data-aos-duration="1200" style={{width:400,height:400, margin:'auto', position:'relative'}}>
                        <img src="/epg.png" style={{width:'100%', position:'absolute',left:-15, top:'50%', transform:'translateY(-50%)'}}/>
            </div>
            <a id={"download3"} download={"StrategyProposal.pdf"} href={"/nfts/StrategyProposal.pdf"} style={{display:'none', opacity:1}} />
            <a className="btn-action style-2"  
            data-aos="fade-up" 
            data-aos-duration="1200" 
            style={{display:'block',margin:'auto', width:300, textAlign:'center'}}
            id="download3" download={"StrategyProposal.pdf"} href={'/nfts/StrategyProposal.pdf'}
            >View Report</a>
                {/* <div className="row">
                    <div className="col-12">
                        <div className="counter__body" data-aos="fade-down" data-aos-duration="1000">
                            <div className="counter">
                                <img src={icon.users} alt="Monteno" />
                                <div className="number-counter">
                                    <CountUp end={100} />K
                                </div>
                                <h5 className="title">Registered User</h5>    
                            </div>
                            <div className="counter">
                                <img src={icon.assets} alt="Monteno" />
                                <div className="number-counter">
                                <CountUp end={5} />M
                                </div>
                                <h5 className="title">Total Assets</h5>    
                            </div>
                            <div className="counter">
                                <img src={icon.trading} alt="Monteno" />
                                <div className="number-counter">
                                <CountUp end={10} />B
                                </div>
                                <h5 className="title">Yearly Trading</h5>    
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    );
}

export default Statistics;