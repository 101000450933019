import React from 'react';
import img from '../assets/images/items/group-ntf-04.png'
import Action from '../components/layouts/Action';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import axios from 'axios';

const Contact = props => {
    return (
        // <div className='page-contact'>
        //     <section className="tf-section contact">
        //         <div className="container">
        //             <div className="row">
        //                 <div className="col-12">
        //                     <div className="block-text center">
        //                         <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>About</span></h1>
        //                         <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Send Message</h5>
        //                         <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">Write Us Message for<br /> Any Reason</h3>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="row">
        //                 <div className="col-md-6">
        //                     <div className="contact-left">
        //                         <ul>
        //                             <li data-aos="fade-up">
        //                                 <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
        //                                 <div className="info">
        //                                     <h5>Address</h5>
        //                                     <p className="fs-18">20, Backway Road, New York, US AB42</p>
        //                                 </div>
        //                             </li>
        //                             <li data-aos="fade-up">
        //                                 <div className="icon"><i className="fas fa-phone-alt"></i></div>
        //                                 <div className="info">
        //                                     <h5>Phone</h5>
        //                                     <p className="fs-18">+123 456 7890</p>
        //                                 </div>
        //                             </li>
        //                             <li data-aos="fade-up">
        //                                 <div className="icon"><i className="fas fa-envelope"></i></div>
        //                                 <div className="info">
        //                                     <h5>Email</h5>
        //                                     <p className="fs-18">hello@nftportfolio.com</p>
        //                                 </div>
        //                             </li>
        //                         </ul>
        //                     </div>
        //                 </div>
        //                 <div className="col-md-6">
        //                     <form className="contact__form">

        //                         <div className="form-group">
        //                             <input className="form-control" type="text" placeholder="Your name" required />
        //                             <input type="email" className="form-control mr-0" id="exampleInputEmail1" placeholder="Your email" required />
        //                         </div>
        //                         <div className="form-group">
        //                             <input className="form-control" type="text" placeholder="Your phone" required />
        //                             <select className="form-control mr-0" id="exampleFormControlSelect1" >
        //                                 <option>Subject</option>
        //                                 <option>Subject 1</option>
        //                                 <option>Subject 2</option>
        //                                 <option>Subject 3</option>
        //                                 <option>Subject 4</option>
        //                             </select>
        //                         </div>
        //                         <div className="form-group">
        //                             <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Your message here"></textarea>
        //                         </div>
        //                         <button type="submit" className="btn-action style-2"><span>Send Now</span></button>
        //                     </form>
        //                 </div>
        //             </div>
                    
        //         </div>
                
        //     </section>
        // </div>
        <div className='page-contact' >
            <section className="tf-section contact" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>Contact</span></h1>
                                <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Contact</h5>
                                {/* <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">Write Us Message for<br /> Any Reason</h3> */}
                            </div>
                        </div>
                    </div>
                    <div className="row" data-aos="fade-up" data-aos-duration="1000">
                        <div className="col-md-12">
                            <form className="contact__form" onSubmit={(e)=>{
                                e.preventDefault();
                                const collections = new FormData(e.target);
                                props.sendFeedback({
                                    name: collections.get("name"),
                                    email: collections.get("email"),
                                    message: collections.get("message"),
                                });
                                axios({
                                    method: 'post',
                                    url: 'https://us-central1-mest-3b41d.cloudfunctions.net/contact',
                                    data:  {
                                        sendTo: "fuse@molotovcocktail.tv",
                                        name: collections.get("name") + " - " + collections.get("email"),
                                        message: collections.get("message"),
                                      }
                                })
                                e.target.reset();

                            }}  style={{position:'relative', zIndex:0}}>

                                <div className="form-group">
                                    <input className="form-control" type="text" name="name" placeholder="Your name" required />
                                    <input type="email" className="form-control mr-0" name="email" id="exampleInputEmail1" placeholder="Your email" required />
                                </div>
                                {/* <div className="form-group">
                                    <input className="form-control" type="text" placeholder="Your phone" required />
                                    <select className="form-control mr-0" id="exampleFormControlSelect1" >
                                        <option>Subject</option>
                                        <option>Subject 1</option>
                                        <option>Subject 2</option>
                                        <option>Subject 3</option>
                                        <option>Subject 4</option>
                                    </select>
                                </div> */}
                                <div className="form-group">
                                    <textarea name="message" className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Your message here"></textarea>
                                </div>
                                <button type="submit" className="btn-action style-2"><span>Send Now</span></button>
                            </form>
                        </div>
                    </div>
                    
                </div>
                
            </section>
        </div>
    );
}

export default Contact;