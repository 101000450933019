
import img from '../assets/images/items/group-ntf-04.png'
import Action from '../components/layouts/Action';
import Footer from '../components/footer/Footer'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Login = (props) => {

    
    const navigate = useNavigate();
    const [error, SetError] = useState();
    const [resend, SetResend] = useState(0);
    const [currentTime, SetTime] = useState(Date.now());

    let ticks 

    useEffect(()=>{
        if(typeof ticks !== "number"){
            ticks = setInterval(()=>{
                SetTime(Date.now());
            }, 1 * 1000);
            console.log(typeof ticks);
        }
        return () => {
            if(typeof ticks === "number") clearInterval(ticks);
        }
    }, []);

    function sendCode(email) {
        axios({
            method: 'post',
            url: 'https://us-central1-mest-3b41d.cloudfunctions.net/sendCode',
            data:  {
                sendTo: email,
              }
          })
          .then((response) => {
            console.log(response.data);
            props.SetEmail(response.data.status);
            if(!response.data.status) {
                SetError("email not found");
            }else{
                SetError();
            }
          })
          .catch((error) => {
            console.log(error);
          });       
    }

    function verify(email, code) {
        axios({
            method: 'post',
            url: 'https://us-central1-mest-3b41d.cloudfunctions.net/verify',
            data:  {
                email: email,
                code: code
              }
          })
          .then((response) => {
            props.SetLogin(response.data.status);
            if(response.data.status)
            {
                props.SetAdmin(response.data.admin);
                navigate("/");
                SetError();
                props.SetCurrentEmail(email);
                props.SetEmail(false);
            }else{
                SetError("invalid code");
            }
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });       
    }
    
    return (
        <div>            
            <section className="tf-section contact" style={{margin:0, padding:0}}>
                <div className="container logincontainer" style={{background:'linear-gradient(45deg, #38287264, #4526b164)', padding: '100px 0', maxWidth: 1000, borderRadius: 29, border:'1px solid #4526b1'}}>
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <img src="/epg.png" style={{width:200}}></img>
                                <h4 data-aos="fade-up" style={{marginBottom: 30}} data-aos-duration="1000">WEB3 & METAVERSE - STRATEGIC CONSULTING WORKSHOP</h4>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <form className="contact__form loginform" onSubmit={e => 
                            {
                                e.preventDefault();
                                const collections = new FormData(e.target);

                                // props.SetLogin(true);
                                // props.SetAdmin(true);
                                // props.SetCurrentEmail(collections.get("email"));
                                // navigate("/");    
                                // return;
                                
                                if(!props.email){
                                    sendCode(collections.get("email"));
                                }else{
                                    verify(collections.get("email"), collections.get("code"));
                                    
                                }
                            }} style={{maxWidth:600,margin: 'auto', padding:3}}>
                                <div className="form-group">
                                    <input type="email" onChange={()=>{
                                        props.SetEmail(false);
                                    }} className="form-control mr-0" id="inputEmail" name="email" placeholder="Your email" required />
                                </div>
                                <div className="form-group" style={{marginBottom:props.email ? 20 : 5, height:props.email ? 60 : 0, transition:'0.25s', overflow:'hidden'}}>
                                    <input type="text" style={{maxWidth:410}} name="code"  className="form-control mr-0" id="enterCode" placeholder=" Enter Code " required={props.email}/>
                                    <a className="btn-action" onClick={()=>{
                                        if(Date.now() > resend){
                                            const email = document.getElementById('inputEmail').value;
                                            sendCode(email);
                                            SetResend(Date.now() + (60 * 1000)); 
                                        }
                                    }} style={{marginLeft:'auto', lineHeight: 2, zIndex:1, opacity: Date.now() > resend ? 1 : 0.5}}><span>{ Date.now() > resend ? "Resend" : Math.floor((resend-currentTime)/1000) }</span></a>
                                </div>
                                <p style={{color:'yellow', textAlign:'center',  display:props.email ? 'block' : 'none'}}>
                                    Sent to Email
                                </p>
                                <button type="submit" className="btn-action style-2" style={{margin:'auto', display:'block'}}><span>Login</span></button>
                            </form>
                            {
                                typeof error === "string"
                                &&
                                <div style={{width:'50%',background:'#FF5C5C', textAlign:'center',  margin: 'auto', padding: 10,marginTop: 10 }}>
                                Error: {error}
                                 </div>
                            }
                        </div>
                    </div>
                    
                </div>
                
            </section>
        </div>
    );
}

export default Login;