import React from 'react';
import CountUp from 'react-countup';

import icon from '../../assets/fake-data/icon';

const TheOffice = () => {
    return (
        <section className="tf-section section-counter" id='theoffice'>
            <div className="container">
            <div className="block-text center">
                <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>TheOffice</span></h1>
                <h1 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">The Office</h1>
            </div>
            <video data-aos="fade-up" data-aos-duration="1000" src="https://player.vimeo.com/progressive_redirect/playback/803125907/rendition/1080p/file.mp4?loc=external&signature=7066b539a3ca8f7617d5133b1ad16a745bc1afd93a87079a9ee107ecf9904b37" style={{width:'80%', display:'block',position:'relative',margin:'30px auto'}} controls/>
            {/* <a className="btn-action style-2" href="https://mest.app/unreal/eaglestreaming/" target="_blank"  data-aos="fade-up" data-aos-duration="1200" style={{display:'block',margin:'auto', maxWidth:400, textAlign:'center'}}>Access EPG Virtual Office</a> */}
            </div>
        </section>
    );
}

export default TheOffice;