import React from 'react';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Scrollbar, A11y } from 'swiper';


import { useEffect } from 'react';
import { useState } from 'react';


import Web3 from 'web3';
import { Button } from 'bootstrap';
import { useRef } from 'react';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ArrowForward, ArrowBack } from '@mui/icons-material';
import axios from 'axios';

const abi = [
  {
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "approved",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "operator",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "bool",
        "name": "approved",
        "type": "bool"
      }
    ],
    "name": "ApprovalForAll",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "Transfer",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      }
    ],
    "name": "claim",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "claimed",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "getApproved",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      }
    ],
    "name": "isApprovedForAll",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "name",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "ownerOf",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "safeTransferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "data",
        "type": "bytes"
      }
    ],
    "name": "safeTransferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "approved",
        "type": "bool"
      }
    ],
    "name": "setApprovalForAll",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes4",
        "name": "interfaceId",
        "type": "bytes4"
      }
    ],
    "name": "supportsInterface",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "symbol",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "tokenURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "transferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];


const contracts = {
  "alashram@epg.ae" : "0x94Ba61a8080C0d0cB60374f6abd6308bC4c5Aa7e",
  "obaid.alqatami@epg.ae" : "0x3067e7ECA0E3a74A879Cb4e37583cf51CB755423",
  "rashed.huraiz@emiratespost.ae" : "0xa6330519Ee32E877f1181BE1Cd7a872B28cdb636",
  "jasem.alawadhi@epg.ae" : "0xD1cC787584F313adF31CAf7E5AE517CE1dbEA811",
  "samer.awajan@emiratespost.ae" : "0x79994ab978aa10e1a7F945768BB6856446132Be2",
  "ahmed.albadi@emiratespost.ae" : "0x365dc47f4EF2793F539F43b7F30F8f7f72Abce7f",
  "ahmed.hegazy@emiratespost.ae" : "0x3d941271ec9CD5D718f4716A7Ac2Fb03128b3b65",
  "maya.albaradie@epg.ae" : "0x14De712df7D727223AAaf07397199B214018889c",
  "angelo.vassiliades@emiratespost.ae" : "0xe0e55cF0aC3077f4fbE23Dd3C7C36E7E387A65D7",
  "shady.fayek@emiratespost.ae" : "0x5435b0203F35D1800e58c8f657ee0c90B1f4F2e9",
  "mohammed.ali@emiratespost.ae" : "0xF92269Ac4280a27e9343Fa6c59a5f8461311339b",
  // "michael.ghantous@emiratespost.ae" : "0xe5d61183E0b884B5d440753230fF3671cc3290E4",
  "abdulla.alhashmi@emiratespost.ae" : "0x1E5d93050CBCe4018Fb157B27bD5785Afd26E83e",
  "hamad.alomran@epg.ae" : "0x06f6FF9daFdaE126B901596519057E34256e127A",
  // "adilam@emiratespost.ae" : "0x79fa970cEd64558335b2bD2B90A0B2Be71348d80",
  "bahaa@wimoapp.com" : "0x3CDa51b0A46b01cc44B583e308500c25B0c7C0C9",
  "wissam@wimoapp.com" : "0x2F0a9C72481dc476737d30a2656c345C6100ccEF",
  "igor@blockchaincenter.ae" : "0x6E689d2059FF57bf2b62D2986B84900908559596",
  "ceo@4c.biz" : "0x06CA8B2E8Af08d306BDBa18c1991877Cf16cfb8A",
  "fuse@molotovcocktail.tv" : "0x725e53371A4eCA8D1b8B7Fce2E97af1144931024",
  "cimoniza@gmail.com" : "0x2f1CA78c679a30B7cEA9a7d51782DDc1a7AEe9A4",
  "rolandoz@att.net.mx" : "0x1d081B71F5b3E3aceA46505A0A4e0b4086Bb7A3F",
  "vijay@mesmr.io" : "0xaebe43B909F14e4958480B479eA77d0C6C39DbD4",
  "vijay.ganeshvenkataraman@rrd.com": "0xc2C8F2168D682d8ffB4395d6c1Ef85ACe6269540",
  }

//0x6dd4c5069129A7D69f9e185F189a321418C2a6Cf
//0xEE0877b4ddE49Bf43494C80C903073fcBcC033b1
//0x8d48e7A63CAa34603000AF26B698deeDd5E4E427
//0x9039D43Ed433eff3cF1Dfa17996e7Bc12A26A198
//0x1826efD4F95C187d94aD7602e9e4692ea51b0A68

const videos = {
  "alashram@epg.ae" : "1 Abdulla Alashram.mp4",
  "obaid.alqatami@epg.ae" : "9 Obaid Mohammad Al Qatami.mp4",
  "rashed.huraiz@emiratespost.ae" : "2 Rashid Al Falasi.mp4",
  "jasem.alawadhi@epg.ae" : "11 Jasem AlAwadhi.mp4",
  "samer.awajan@emiratespost.ae" : "3 Samer Awajan.mp4",
  "ahmed.albadi@emiratespost.ae" : "15 Ahmed Al Badi.mp4",
  "ahmed.hegazy@emiratespost.ae" : "5 Ahmed Hegazy.mp4",
  "maya.albaradie@epg.ae" : "7 Maya AlBaradie.mp4",
  "angelo.vassiliades@emiratespost.ae" : "12 Angelo Vassiliades.mp4",
  "shady.fayek@emiratespost.ae" : "10 Shady Fayek.mp4",
  "mohammed.ali@emiratespost.ae" : "8 Mohammed Ali.mp4",
  // "michael.ghantous@emiratespost.ae" : "",
  "abdulla.alhashmi@emiratespost.ae" : "4 Abdulla Alhashimi.mp4",
  "hamad.alomran@epg.ae" : "6 Hamad Al Shamsi.mp4",
  // "adilam@emiratespost.ae" : "",
  "bahaa@wimoapp.com" : "14 Mohammed Bahaa.mp4",
  "wissam@wimoapp.com" : "13 Wissam Aboueida.mp4",
  "igor@blockchaincenter.ae" : "16 Igor Arkhypenko.mp4",
  "ceo@4c.biz" : "17 Dr. Marwan Alzarouni.mp4",
  "fuse@molotovcocktail.tv" : "1 Abdulla Alashram.mp4",
  "cimoniza@gmail.com" : "1 Abdulla Alashram.mp4",
  "rolandoz@att.net.mx" : "19 Rolando Zubiran.mp4",
  "vijay@mesmr.io" : "18 Vijay Venkataraman.mp4",
  "vijay.ganeshvenkataraman@rrd.com": "18 Vijay Venkataraman.mp4",
  }

const Team = (props) => {
    // const { active, account, library, connector, activate, deactivate } = useWeb3React()

    const active=props.active;
    const account=props.account;
    const library=props.library;
    const connector=props.connector;
    const activate=props.activate;
    const deactivate=props.deactivate;
    const connect=props.connect;
    const disconnect=props.disconnect;
    const web3 = new Web3(window.ethereum);

    const [contract, SetContract] = useState(null);
    const [claimed, SetClaimed] = useState(false);
    const [claiming, SetClaiming] = useState(false);
    const metamask = props.metamask;
    const SetMetamask = props.SetMetamask;
    const swiper = useRef(null);
    
    useEffect(()=>{
      const check = async () => {
        if(contract){
          SetClaimed(await contract.methods.claimed().call());
        }
      };
      check();
    },[contract]);

    useEffect(()=>{
      if(typeof contracts[props.currentEmail] !== "undefined") SetContract(new web3.eth.Contract(abi, contracts[props.currentEmail]));
    }, [props.currentEmail])

    const data = props.data;
    return (
        <div>
            <section className="tf-section team" id='participants'>
            <div className="container">
               <div className="block-text center">
                <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>Team</span></h1>
                <h1 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Team</h1>
                </div>
                
                <div className="row mt-53">
                <button onClick={()=>{
                  swiper.current.swiper.slidePrev();
                }} id="leftbtn">
                  <ArrowBack sx={{fontSize:24}}/>
                </button>
                <button onClick={()=>{
                  swiper.current.swiper.slideNext();
                }} id="rightbtn">
                  <ArrowForward sx={{fontSize:24}}/>
                </button>
                    <div className="col-md-12" data-aos="fade-up" data-aos-duration="1000">
                    <Swiper
                        modules={[Pagination , Scrollbar, A11y]}
                        spaceBetween={30}
                        ref={swiper}
                        pagination={{ clickable: true }}
                        onActiveIndexChange={e => {

                        }}
                        breakpoints={{
                            0: {
                                slidesPerView: 3,
                              },
                            767: {
                              slidesPerView: 3,
                            },
                            991: {
                              slidesPerView: 3,
                            },
                          }}
                        scrollbar={{ draggable: true }}                
                    >
                        {
                            data.map((data,index) => (
                                <SwiperSlide key={index}>
                                    <video className="participants" onMouseEnter={e => {
                                      e.target.play();
                                    }}

                                    onMouseLeave={e => {
                                      e.target.currentTime = 0;
                                      e.target.pause();
                                    }}
                                    
                                    style={{marginBottom: 50, marginLeft:'auto', marginRight:'auto', display:'block'}} loop={true}>
                                        <source src={"/nfts/"+(index+1)+" "+data+".mp4"} type="video/mp4"/>
                                    </video>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                    <div className='claimbtn'>
                    <a id={"download2"} download={"Avatar NFT.mp4"} href={typeof videos[props.currentEmail] === "undefined" ? "" : "/nfts/" + videos[props.currentEmail] } style={{display:'none', opacity:1}} />
                    
                    {props.isClaimedTeam === true ? (
                      <>
                      <a download={"Avatar NFT.mp4"} 
                      href={typeof videos[props.currentEmail] === "undefined" ? "" : "/nfts/" + videos[props.currentEmail] } 
                      className="btn-action style-2" 
                      style={{}}
                      >
                        Download NFT
                      </a>
                      {/* <p style={{color:'white'}}>Thank you for claiming, please allow up to 12hrs to receive your NFT in your wallet.</p> */}
                      </>
                    ): (
                    <a download={"Avatar NFT.mp4"} 
                        href={typeof videos[props.currentEmail] === "undefined" ? "" : "/nfts/" + videos[props.currentEmail] } 
                        onClick={async e => {
                        if(!claimed || typeof videos[props.currentEmail] === "undefined" || !active){
                          e.preventDefault();
                        }

                        if(!active){ 
                          await connect();   
                        }else{
                          if(!claiming && 
                            !props.isClaimedTeam &&
                            // !claimed 
                            contract !== null){ 
                            try {
                              SetClaiming(true);
                              // const r = await contract.methods.claim(account).send({
                              //   from: account
                              // });
                              // if(r.status) {
                                  SetClaimed(true);
                                  props.sendClaimTeam({
                                   isclaim: true,
                                   email: props.currentEmail
                                  })
                                  axios({
                                    method: "post",
                                    url: "https://us-central1-mest-3b41d.cloudfunctions.net/contact",
                                    data: {
                                      sendTo: "fuse@molotovcocktail.tv",
                                      name: props.currentEmail,
                                      message: `Your message is ${props.currentEmail} this email address cliam ${true} with ${account} this wallet address from claim Team NFT.`,
                                 },
                                  });
                                  document.getElementById("download2").click();
                              SetClaiming(false);
                            } catch (err) {
                              SetClaiming(false);
                            }
                          }
                        }
                      
                    }} 
                    className="btn-action style-2" 
                    data-aos="fade-up"
                    data-aos-duration="1200" 
                    style={{}}>
                        {active ?
                        ( props.isClaimedTeam
                          // claimed 
                          ?
                        (typeof videos[props.currentEmail] === "undefined" ? "Claimed" : "Download NFT") 
                        : (claiming ?  "Claiming..."
                        : (contract === null ? "Unavailable" : "Claim") )) 
                        : "Claim"}
                    </a>
                    )}

                    <button onClick={()=>{
                      SetMetamask(true);
                    }} className="btn-action" data-aos="fade-up" data-aos-duration="1200">How to get a wallet</button>
                    </div>
                    {props.isClaimedTeam === true ? 
                    <p style={{color:'white', textAlign: 'center', marginTop:'15px'}}>Thank you for claiming, please allow up to 12hrs to receive your NFT in your wallet.</p>: 
                    <p style={{color:'white', textAlign: 'center', marginTop:'15px'}}>Make sure you are on Polygon Mainnet</p>
                    }
                    </div>
                </div>
            </div>
        </section> 
            {/* <section id="nft" style={{marginTop: 50}}>
                <div className='container'> 
                <div className="block-text center">
                <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>NFT</span></h1>
                <h1 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">NFT</h1>
                </div>
                <div className="row mt-53">
                    <div className="col-md-12" data-aos="fade-up" data-aos-duration="1000">
                    <video src="/nfts/EPG NFT.mp4" style={{width:450, display:'block', margin:'auto', position:'relative', top: -25}} loop={true} autoPlay={true}/>

                    <a onClick={async () => {
                      if(!active){ 
                        await connect();   
                      }else{
                        
                      }

                    }} className="btn-action style-2"  data-aos="fade-up" data-aos-duration="1200" style={{display:'block',margin:'auto', width:300, textAlign:'center'}}>{active ? "Claim" : "Connect"}</a>
                       
                    </div>
                </div>
                
                </div>
            </section> */}
        </div>
        
    );
}

export default Team;